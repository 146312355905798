



import polygon from '../../assest/BootsEmpire/about-us-page-banner.jpg'
import { Box } from "@mui/material"
import '../../styles/playnif50/AboutUsStyle.css'
import BootsEmpireHeader from "../../common/playnif50/header/BootsEmpireHeader"
import BootsEmpireFooter from '../../common/playnif50/Footer/BootsEmpireFooter'
import EnterpriseImg from '../../assest/BootsEmpire/slider-02-1.jpg'




const HowToPlay = () => {

    return (
        <>
            <BootsEmpireHeader />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>How To Play</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="">
                    <p className=" fw-bold">HOW TO PLAY</p>
                    <p className=" fw-bold">Step 1:</p>
                    <p className=" ms-3">You can download the game PlayNIF50 app directly from the LINK shared.</p>
                    <p className=" fw-bold">Step 2:</p>
                    <p className=" ms-3">Select the game you wish to join the contest for amongst Nifty50 (closing/opening), Bank Nifty (closing/opening), FinNifty (closing/opening) or Midcapselect Nifty(closing/opening) or all four contests. </p>
                    <p className=" fw-bold">Step 3:</p>
                    <p className=" ms-3">Join the contest with Rs. 50/- by adding the same to the you application account and select your contest..</p>
                    <p className=" fw-bold">Step 4:</p>
                    <p className=" ms-3">Now using your skills and assessments make a fantasy guess of closing or opening your chosen contest and lock your guess as assessed.</p>
                    <p className=" fw-bold">Step 5:</p>
                    <p className=" ms-3">The final results of winners whose guess is closest to the actually closing/opening index price will announced/displayed @ 3:35 PM (closing) and opening @ 9:10 a.m (opening). as per India Standard Time (IST).</p>
                </div>
            </div>


            <BootsEmpireFooter />

        </>
    )
}

export default HowToPlay