import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import BootsEmpireHeader from '../../common/playnif50/header/BootsEmpireHeader'
import BootsEmpireFooter from '../../common/playnif50/Footer/BootsEmpireFooter'



function ErrorPage() {
  return (
    <>
      <BootsEmpireHeader />

      <div class="verticalcenter py-5">
        <div class="container">
          <div id="notfound" class="text-center ">
            <h1>😮</h1>
            <h2>Oops! Page Not Be Found</h2>
            <p>Sorry but the page you are looking for does not exist.</p>
            <Link to={'/'}><Button style={{ backgroundColor: '#18231d', color: 'white', textTransform: 'capitalize',zIndex:-666 }}>Back to homepage</Button></Link>
          </div>
        </div>
      </div>
      <BootsEmpireFooter />

    </>
  )
}

export default ErrorPage
