


import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";

import MainHome from './page/playnif50/MainHome'
import ContactUs from "./page/playnif50/ContactUs";
import AboutUs from "./page/playnif50/AboutUs";
import TermsConditions from "./page/playnif50/TermsConditions";
import PrivacyPolicy from "./page/playnif50/PrivacyPolicy";
import VideoGallery from "./page/playnif50/VideoGallery";
import FAQPage from "./page/playnif50/FAQ";
import PhotoGallery from "./page/playnif50/PhotoGallery";
import Blogs from "./page/playnif50/Blogs";
import CourseDetail from "./page/playnif50/CourseDetail";
import AdvancedStockMarketCourse from "./page/playnif50/AdvancedStockMarketCourse";
import Subscribe from "./page/playnif50/Subscribe";
import ErrorPage from "./page/playnif50/ErrorPage";
import ScrollToTop from "./components/ScrollToTop";
import RefundPolicy from "./page/playnif50/RefundPolicy";
import HowToPlay from "./page/playnif50/HowToPlay";

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <BrowserRouter>
        <div className="top-to-btm">
          {showTopBtn && (
            <FaAngleUp
              className="icon-position icon-style "
              style={{ zIndex: 199 }}
              onClick={goToTop}
            />
          )}
        </div>

        <ScrollToTop/>
        
        <Routes>

          {/* Boots Empire */}

          <Route path='/' element={<MainHome />} />
          <Route path='/contact_us' element={<ContactUs />} />
          <Route path='/about_us' element={<AboutUs />} />
          <Route path='/terms_conditions' element={<TermsConditions />} />
          <Route path='/privacy_policy' element={<PrivacyPolicy />} />
          <Route path='/refund_policy' element={<RefundPolicy />} />
          <Route path='/how_to_play' element={<HowToPlay />} />
          <Route path='/video_gallery' element={<VideoGallery />} />
          <Route path='/FAQ' element={<FAQPage />} />
          <Route path='/photo_gallery' element={<PhotoGallery />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='*' element={<ErrorPage />} />
          <Route path='/advanced_stock_market_course' element={<AdvancedStockMarketCourse />} />
          <Route path='/course_detail' element={<CourseDetail />} />


          {/* <Route path='/subscribe' element={<Subscribe />} /> */}

          

        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
