import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

import 'react-pro-sidebar/dist/css/styles.css';
import "./sideBar.css"
import { MdEventNote, MdOutlineQuestionAnswer,MdOutlineConstruction,MdDisplaySettings } from "react-icons/md";
import { GiIndiaGate, GiWorld } from "react-icons/gi";
import { Link } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { BiMessageSquareDetail } from "react-icons/bi";
import { GiSkills } from "react-icons/gi";
import { CgWebsite } from "react-icons/cg";
import { IoMdPhotos } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import { MdTour } from "react-icons/md";
import { HiOutlineViewGridAdd } from "react-icons/hi";

const SideBar = () => {


  return (
    <>
      <div id="main-div">
        <ProSidebar
          style={{ height: "100%", position: "absolute" }}
        >
          <div className="sidebar-body-div">
            <SidebarContent className="main-div">
              <Menu className="sidebar-content-div"
                iconShape="circle"
              >
                <p className="Menus-p">MENUS</p>

                <MenuItem icon={<GoHome />}> Home <Link to={'/'} /></MenuItem>

                <MenuItem icon={<FaUser />}> About Us <Link to={'/about_us'} /></MenuItem>


                <SubMenu title="Courses" icon={<HiOutlineViewGridAdd />}>
                <MenuItem icon={<MdEventNote />}>Advanced Stock Market Course<Link to="/advanced_stock_market_course" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Advanced Technical Analysis Course<Link to="/advanced_technical_analysis_course" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Advanced Option Trading Course<Link to="/advanced_option_trading_course" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Advanced Fundamental Analysis Course<Link to="/advanced_fundamental_analysis_course" /></MenuItem>
                </SubMenu>

                <MenuItem icon={<MdOutlineConstruction />}> Register Demo <Link to={'/register_demo'} /></MenuItem>


                <SubMenu title="Admission" icon={<GiSkills />}>
                  <MenuItem icon={<MdEventNote />}>Admission Process<Link to="/admission_process" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Rules & Regulations<Link to="/rules_regulations" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Certifications<Link to="/certifications" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Eligibility Criteria<Link to="/eligibility_criteria" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Fee Submission<Link to="/fee_submission" /></MenuItem>
                </SubMenu>



                <SubMenu title="Franchisee" icon={<MdDisplaySettings />}>
                  <MenuItem icon={<MdEventNote />}>Franchisee Process<Link to="/franchisee_process" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Franchisee Support<Link to="/franchisee_support" /></MenuItem>
                </SubMenu>
                

                <MenuItem icon={<MdTour />}> Subscribe <Link to={'/subscribe'} /></MenuItem>

                <MenuItem icon={<IoMdPhotos />}>Photo Gallery <Link to={'/photo_gallery'} /></MenuItem>

                <MenuItem icon={<MdOutlineQuestionAnswer />}>FAQs<Link to={'/FAQ'} /></MenuItem>

                <MenuItem icon={<CgWebsite />}>Blogs<Link to={'/blogs'} /></MenuItem>

                <MenuItem icon={<BiMessageSquareDetail />}>Contact Us<Link to={'/contact_us'} /></MenuItem>


              </Menu>
            </SidebarContent>
          </div>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;

