import BootsEmpireFooter from "../../common/playnif50/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/playnif50/header/BootsEmpireHeader"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Button } from "@mui/material";
import TopBannersData from "../../data/playnif50/TopBannersData";
import '../../styles/playnif50/MainHomeStyle.css'
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import OurDivissionData from "../../data/playnif50/OurDivissionData";
import TradingAndContactingServicesData from "../../data/playnif50/TradingAndContactingServicesData";
import FacilityServicesData from "../../data/playnif50/FacilityServicesData";
import CivilWorkData from "../../data/playnif50/CivilWorkData";
import SustainabilityData from "../../data/playnif50/SustainabilityData";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurClientData from "../../data/playnif50/EventPartners";
import ClientSatisfactionData from "../../data/playnif50/ClientSatisfactionData";
import ClientTestimonialData from "../../data/playnif50/ClientTestimonialData";
import FeaturedProjectSlider from "../../components/FeaturedProjectSlider";
import MaqFeaturedProjectData from "../../data/playnif50/MaqFeaturedProjectData";
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useState } from "react";
import CoursesData from "../../data/playnif50/CoursesData";
import WebinarsData from "../../data/playnif50/WebinarsData";
import FreeCourseData from "../../data/playnif50/FreeCourseData";
import OptionsTradingCourseData from "../../data/playnif50/OptionsTradingCourseData";
import TechnicalAnalysisCourseData from "../../data/playnif50/TechnicalAnalysisCourseData";
import UpcomingFreeLiveWebinarData from "../../data/playnif50/UpcomingFreeLiveWebinarData";
import { FaRegUser } from "react-icons/fa6";
import UpcomingLiveClassesData from "../../data/playnif50/UpcomingLiveClassesData";
import EventPartners from "../../data/playnif50/EventPartners";
import img1 from '../../assest/playnif50/pricing light Theme.png'
import img2 from '../../assest/playnif50/college_workshop_homepage.jpeg'
import axios from 'axios';
import ThankYouDialoge from "../../components/ThankYouDialoge";
import android from "../../assest/playnif50/android.png";
import appdebug from "../../assest/playnif50/playnif50.apk";


const mangeTabActive = {
    color: '#0F677A',
    borderBottom: '3px solid #0F677A',
    backgroundColor: 'white'
}


const MainHome = () => {

    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }


    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    const [EnquiryData, SetEnquiryData] = useState({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: '',
    })

    const [errorName, setErrorName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorMobile, setErrorMobile] = useState("");
    const [bordrColor, setBordrColor] = useState("");

    const navigate = useNavigate()

    const onInputChnge = (e) => {
        const inpName = e.target.name;
        const inpVal = e.target.value;
        const cloned = { ...EnquiryData }
        cloned[inpName] = inpVal;
        SetEnquiryData(cloned);
    }


    const onSendClick = async (e) => {
        e.preventDefault();

        try {
            // Reset errors and color
            setErrorName("");
            setErrorEmail("");
            setErrorMobile("");
            setBordrColor("");

            // Perform input validation here before making the API call
            if (
                EnquiryData.name.length > 2 &&
                EnquiryData.email.includes("@") &&
                EnquiryData.mobile.length === 10 &&
                EnquiryData.subject.length > 2 &&
                EnquiryData.message.length > 2
            ) {
                setBordrColor('1px solid green');
                const sign_up = await axios.post(
                    'https://backoffice.adamclick.com/api/auth/lead/register',
                    { ...EnquiryData }
                );
                SetEnquiryData(sign_up.data);
                console.log(sign_up.data);

                // Clear the form fields
                SetEnquiryData({
                    name: '',
                    email: '',
                    mobile: '',
                    subject: '',
                    message: '',
                });

                setOpen(true);
                // Provide user feedback or navigation here if needed
                // navigate('/contact')
                console.log('Form data submitted successfully!');
            } else {
                if (EnquiryData.name.length <= 2 || EnquiryData.name === '') setErrorName("enter name");
                if (!EnquiryData.email.includes("@") || EnquiryData.email === '') setErrorEmail("enter email");
                if (EnquiryData.mobile.length !== 10 || EnquiryData.mobile === '') setErrorMobile("enter valid 10 digit number");

                setBordrColor('1px solid white');
                // Provide user feedback for validation errors
                console.log('Form validation failed');
            }
        } catch (error) {
            // Provide user-friendly error message
            console.log('Error submitting form:', error.message);
        }
    };


    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 4,
                },
            }
        ],
    };
    return (
        <>
            <BootsEmpireHeader />

            <Box id="BoxId">
                <Swiper
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    navigation={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className=""
                >
                    {TopBannersData?.map(({ id, name, src }) => {
                        return (
                            <SwiperSlide key={id} className="">
                                <div className="custom-img">
                                    <img src={src} alt="img" />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>

            <div className="DownloadAppDIV">
                <h1 className=" fw-bold fs-2 mb-0">Get the PLAYNIF50 app</h1>
                <a href={appdebug} download>
                    <Button className='butn'><img className='DnldTheApp' src={android} alt="img" /><span>Download NOW!</span></Button>
                </a>
            </div>

            <Box className='featured_projectweworkIn'>
                <div className="logistic_topdiv pt-0">
                    <h1 className=" fw-bold">Explore Courses & Webinars</h1>
                </div>


                <Tabs value={value} onChange={onHandleChange} className="tabs_latest">
                    <div className="head_tabs">
                        <TabsList>
                            <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='mangetab1' value={0}>Popular</Tab>
                            <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='mangetab1' value={1}>Courses </Tab>
                            <Tab style={value === 2 ? { ...mangeTabActive } : {}} className='mangetab1' value={2}>Webinars </Tab>
                            <Tab style={value === 3 ? { ...mangeTabActive } : {}} className='mangetab1' value={3}>Free </Tab>

                        </TabsList>
                    </div>
                    <div className="latest_TabPanelDiv">

                        <TabPanel value={0}>
                            <FeaturedProjectSlider detailsData={MaqFeaturedProjectData} />
                        </TabPanel>
                        <TabPanel value={1}>
                            <FeaturedProjectSlider detailsData={CoursesData} />
                        </TabPanel>
                        <TabPanel value={2}>
                            <FeaturedProjectSlider detailsData={WebinarsData} />
                        </TabPanel>
                        <TabPanel value={3}>
                            <FeaturedProjectSlider detailsData={FreeCourseData} />
                        </TabPanel>
                    </div>
                </Tabs>
            </Box>


            <Box className='featured_projectweworkIn'>
                <div className="logistic_topdiv">
                    <h1 className=" fw-bold">Our top Options trading courses</h1>
                </div>

                <FeaturedProjectSlider detailsData={OptionsTradingCourseData} />

            </Box>


            <Box className='featured_projectweworkIn'>
                <div className="logistic_topdiv">
                    <h1 className=" fw-bold">Our top Technical Analysis courses</h1>
                </div>

                <FeaturedProjectSlider detailsData={TechnicalAnalysisCourseData} />

            </Box>


            <div className="UpcomingFreeLiveWebinars">
                <h1 className='fw-bold text-center py-3'>Upcoming Free Live Webinars</h1>
                <div className="UpcomingDiv">
                    {UpcomingFreeLiveWebinarData?.map((item, index) => (
                        <Link to={`${item.linkTage}`}>
                            <div className="project_LiveWebinars">
                                <div>
                                    <img src={item.imgSrc} alt="img" />
                                </div>
                                <div>
                                    <h6>{item.companyName}</h6>
                                    <p className=" fw-normal"><FaRegUser className="me-1" />{item.projectName}</p>
                                </div>

                                <Button className=' bg-primary-subtle' fullWidth>Read More</Button>
                            </div>
                        </Link>
                    ))}
                </div>

            </div >

            <div className="UpcomingFreeLiveWebinars">
                <h1 className='fw-bold text-center py-3'>Upcoming Live Classes</h1>
                <div className="UpcomingDiv">
                    {UpcomingLiveClassesData?.map((item, index) => (
                        <Link to={`${item.linkTage}`}>
                            <div className="project_LiveWebinars">
                                <div>
                                    <img src={item.imgSrc} alt="img" />
                                </div>
                                <div>
                                    <h6>{item.companyName}</h6>
                                    <p className=" fw-normal"><FaRegUser className="me-1" />{item.projectName}</p>
                                </div>

                                <Button className=' bg-primary-subtle' fullWidth>Read More</Button>
                            </div>
                        </Link>
                    ))}
                </div>

            </div >

            <Box className='logistic_clients'>
                <h1>Our Event Partners</h1>
                <div className="our_partner">
                    <Slider {...settings}>
                        {EventPartners?.map((item, index) => (
                            <div key={item.id} className="Projects_topimg">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </Box>


            {/* <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row me-0">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={img1} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <h6>Subscription Plans</h6>
                        <p className=" fw-bold">Don’t wait, take a leap in your trading journey</p>
                        <p>Playnif50 tailored Courses & Webinars are ready to help you achieve your stock market goals.</p>
                        <Button className="morebtn">Choose Plan</Button>
                    </div>
                </Box>
            </Box>

            <div className="Sustainability_div">
                <h1 className=" fw-bold py-3">We Just Keep Growing</h1>
                <h6>Playnif50 Community keeh6s getting bigger everyday.</h6>
                <div className="Sustainability_slidediv">
                    {SustainabilityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="Sustainability_div_slide">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>

                    ))}
                </div >
            </div >

            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row me-0">
                    <div className="textdiv col-lg-6 col-12">
                        <h6>College Workshops</h6>
                        <p className=" fw-bold">Spreading Financial Wisdom Across Students</p>
                        <p>At Playnif50 we are driven by a mission to promote financial literacy among students with our Finance Literacy Workshop.</p>
                        <Button className="morebtn">Learn More</Button>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={img2} alt="img" />
                    </div>
                </Box>
            </Box>

            <div className="">
                <section className="contact-us section1">
                    <h1 className=" text-center fw-bold text-white pt-5">Get connected with us</h1>
                    <div className="">
                        <div className="inner">
                            <div className="row me-0 justify-content-center py-5">
                                <div className="col-lg-6 card-color shadow"
                                    style={{ background: "", borderRadius: "5px 0 0 5px", border: '1px solid white' }}
                                >
                                    <div className="contact-us-form">
                                        <h5 className=" text-white">
                                            If you have any questions please fell free to contact with
                                            us.
                                        </h5>
                                        <form className="form">
                                            <div className="row me-0">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.name}
                                                            style={{ border: bordrColor, borderRadius: '4px' }}
                                                            placeholder="Name"
                                                            required=""
                                                        />
                                                        <p style={{ color: 'red', fontSize: '12px' }} className="mb-0">{errorName}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.email}
                                                            style={{ border: bordrColor, borderRadius: '4px' }}
                                                            placeholder="Email"
                                                            required=""
                                                        />
                                                        <p style={{ color: 'red', fontSize: '12px' }} className="mb-0">{errorEmail}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.mobile}
                                                            style={{ border: bordrColor, borderRadius: '4px' }}
                                                            placeholder="Phone"
                                                            required=""
                                                        />
                                                        <p style={{ color: 'red', fontSize: '12px' }} className="mb-0">{errorMobile}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="subject"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.subject}
                                                            placeholder="Subject"
                                                            required=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <textarea
                                                            name="message"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.message}
                                                            placeholder="Your Message"
                                                            required=""
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <Button fullWidth onClick={onSendClick} className="btnbtnbgprimary" type="submit">
                                                            Send
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className=' col-lg-6 col-12 p-1'
                                    style={{ background: "", borderRadius: "0 5px 5px 0", border: '1px solid white' }}
                                >
                                    <iframe
                                        className=""
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14005.075884964184!2d77.28705034459993!3d28.651664030232077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb410a2b0539%3A0xb1d55d16b85a6068!2sKanishka%20Complex%2C%20Karkardooma%2C%20Anand%20Vihar%2C%20Delhi%2C%20110092!5e0!3m2!1sen!2sin!4v1728457821831!5m2!1sen!2sin"
                                        width="100%"
                                        height="100%"
                                        style={{ border: 0 }}
                                        allowfullscreen=""
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>

            <ThankYouDialoge open={open} setOpen={setOpen} handleClose={handleClose} /> */}



            <BootsEmpireFooter />
        </>
    )
}

export default MainHome