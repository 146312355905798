import React, { useState } from 'react'
import Logo from "../../../assest/playnif50/logoPlay.png";
import "./BootsEmpireHeaderStyle.css";
import { Box, Button, Drawer } from '@mui/material';
import { Link } from 'react-router-dom';
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { FaSquareTwitter, FaSquareInstagram, FaSquareFacebook } from "react-icons/fa6";
import SideBar from '../sidebar/SideBar';
import { MdOutlineMenu } from "react-icons/md";


const BootsEmpireHeader = () => {

    const [openSide, setOpenSide] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenSide(newOpen);
    };

    return (
        <>

            {/* <Box className="top_contactdiv">
                <div>
                    <ul>
                        <li>
                            <Link to="tel:+91-9582558785">
                                <IoCall className='fs5' /> +91-9582558785
                            </Link>
                        </li>
                        <li>
                            <Link to="mailto:info@abarissoftech.com">
                                <MdOutlineEmail className='fs5' /> info@abarissoftech.com
                            </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            <Link to="" target="_blank">
                                <BsLinkedin className='fs3' />
                            </Link>
                        </li>
                        <li>
                            <Link to="" target="_blank">
                                <FaSquareFacebook className='fs3' />
                            </Link>
                        </li>
                        <li>
                            <Link to="" target="_blank">
                                <FaSquareTwitter className='fs3' />
                            </Link>
                        </li>
                        <li>
                            <Link to="" target="_blank">
                                <FaSquareInstagram className='fs3' />
                            </Link>
                        </li>


                    </ul>
                </div>
            </Box> */}


            <Box className="abarisDan_Header">

                <div className='tanishkadiv_mobile'>
                    <Button onClick={toggleDrawer(true)}><MdOutlineMenu className=' fs-1textwhite' /></Button>
                    <Drawer open={openSide} onClose={toggleDrawer(false)}>
                        <SideBar setOpenSide={setOpenSide} />
                    </Drawer>
                </div>

                <Link className='logo_divbox' to="/">
                    <img className="logo" src={Logo} alt='Logo' />
                    {/* <span className='compnyName'>Boost Empire  <span> General Trading & Contracting Co.</span></span> */}
                </Link>

                <Box className='abarisDan_rightUL'>
                    <ul className="abarisDan_liauto">
                        <li>
                            <Link to="/"> Home </Link>
                        </li>

                        {/* <li>
                            <Link to="/photo_gallery"> Gallary </Link>
                        </li> */}


                        <li className='open_list'>
                            <Link> Courses </Link>
                            <Box className='hidegroup'>
                                <div className='Ourboots_maindiv'>
                                    <h6><Link to={'/advanced_stock_market_course'}> Advanced Stock Market Course</Link></h6>
                                    <h6><Link to={'/advanced_technical_analysis_course'}> Advanced Technical Analysis Course</Link></h6>
                                    <h6><Link to={'/advanced_option_trading_course'}> Advanced Option Trading Course</Link></h6>
                                    <h6><Link to={'/advanced_fundamental_analysis_course'}> Advanced Fundamental Analysis Course</Link></h6>
                                </div>
                            </Box>
                        </li>

                        <li>
                            <Link to="/register_demo"> Register Demo </Link>
                        </li>


                        <li className='open_list'>
                            <Link> Admission </Link>
                            <Box className='hidegroup'>
                                <div className='Ourboots_maindiv'>
                                    <h6><Link to={'/admission_process'}> Admission Process</Link></h6>
                                    <h6><Link to={'/rules_regulations'}> Rules & Regulations</Link></h6>
                                    <h6><Link to={'/certifications'}> Certifications</Link></h6>
                                    <h6><Link to={'/eligibility_criteria'}> Eligibility Criteria</Link></h6>
                                    <h6><Link to={'/fee_submission'}> Fee Submission</Link></h6>

                                </div>
                            </Box>
                        </li>
                        <li className='open_list'>
                            <Link> Franchisee </Link>
                            <Box className='hidegroup'>
                                <div className='Ourboots_maindiv'>
                                    <h6><Link to={'/franchisee_process'}> Franchisee Process</Link></h6>
                                    <h6><Link to={'/franchisee_support'}> Franchisee Support</Link></h6>
                                </div>
                            </Box>
                        </li>

                        <li>
                            <Link to="/subscribe">Subscribe</Link>
                        </li>

                        {/* <li>
                            <Link to="/contact_us"> Contact Us </Link>
                        </li> */}



                        <li>
                            <Link className='loginbtnn' to="https://customer.playnif50.com/login-area"> Login </Link>
                        </li>

                    </ul>
                </Box>
            </Box>
        </>
    )
}

export default BootsEmpireHeader